//** GinieHealth InviteUsername Popup code UI **//

import React, { useEffect, useState } from "react";
import "./supervisor.scss";
import "./MyCalender.scss";
import { Button, Modal, ModalTitle, Row, Col, Form } from "react-bootstrap";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { Controller, useForm } from "react-hook-form";
import { SubmitButton } from "../../components/submit.button";
import { useIgurooTranslations } from "../../hooks";
import { _getImage } from "../../common/utils/commonFunctions";
import { FormErrorComponent } from "../../components/form.error.component";
import { UserPopupSV } from "../../FormSchemas/supervisor.email";
import { yupResolver } from "@hookform/resolvers/yup";
import AutoCompleteUsers from "./AutoCompleteUsers";
import { CreateUserAssignmentApi } from "@iguroo/ig-react-assessment-package";
import { useGetAssessments } from "../../hooks/useGetAssessments";
import { GetAssessmentsApi } from "@iguroo/ig-react-assessment-package";
import moment from "moment";
import { DatePicker } from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import FeedbackInvite from "../../components/FeedbackInvitePopup/FeedbackInvite";

const InviteUserPopup = (props) => {
  const { assessment_id, version } = props;
  const { genieLabsTranslations, formErrors } = useIgurooTranslations();
  const { assessments, fetchInitialAssessments } = useGetAssessments({
    apiFunction: GetAssessmentsApi,
    initialQuery: { assessment_id, version },
  });

  useEffect(() => {
    fetchInitialAssessments({ assessment_id, version });
  }, []);
  // console.log("checking for the assesment in userpopup", assessments);

  const [dueDateDefault, setdueDateDefault] = useState();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(UserPopupSV(formErrors)),
    defaultValues: {
      timeField: "23:59",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dueDate, setDueDate] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [datechecking1, setDatechecking1] = useState();
  const [newValue, setNewValue] = useState(new Date());
  const [newMAXValue, setNewMAXValue] = useState();
  const currentTime = Math.floor(Date.now() / 1000);
  const today = new Date().getTime();

  useEffect(() => {
    const validTillDate = assessments.map((item, index) => {
      const datechecking = item.valid_till;
      const tomorrow = new Date(moment.unix(datechecking));
      setDatechecking1(tomorrow);
      setNewValue(tomorrow);
      setNewMAXValue(tomorrow);
      //console.log("LOGGG13 setDatechecking1", newValue, datechecking1,  tomorrow);
      const convertingData = moment.unix(datechecking).format("YYYY-MM-DD");
      setdueDateDefault(convertingData);
      setValue("dateField", convertingData);
      setValue("timeField", "23:59");
      setValue("availability", "rightNow");
      var currDate = new Date(new Date().getTime());
      var currDate2 = new Date((datechecking + 86340) * 1000);
      var time_st = moment.unix(currDate2);
      var v = time_st.diff(currDate, "months");
      //console.log("LOGGG12 datechecking = ", v,  currDate, currDate2);
      const diffMain = Math.ceil((currDate2 - currDate) / (1000 * 3600 * 24));
      console.log("LOGGG12  CURR DATE DIFF", diffMain);
      if (diffMain < 2) {
        setInvalidDate(true);
        setShowInvite(true);
        setIsSubmitting(false);
      } else {
        setInvalidDate(false);
        setShowInvite(true);
      }
      if (currentTime < datechecking) {
        setDueDate(false);
      } else {
        setDueDate(true);
      }
    });
  }, [assessments]);

  const [enableInput, setEnableInput] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [usernames, setUsernames] = useState([]);
  const [userData, setUserData] = useState([]);
  const [currentUsernameValue, setCurrentUsernameValue] = useState("");
  const [currentUsername, setCurrentUsername] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [days, setDays] = useState("");
  const [validDueDateFlag, setValidDueDateFlag] = useState(false);
  const [btnClickedFlag, setBtnClickedFlag] = useState(false);
  const [availableBeforeDueDate, setAvailableBeforeDueDate] = useState(0);
  const [rightNowFlag, setRightNowFlag] = useState(false);
  const [uIds, setUIds] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const [dueDateTime, setDueDateTime] = useState("");
  const [wrongDate, setWrongDate] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [selectedOption, setSelectedOption] = useState("rightNow");
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate());

  const callApi = () => {
    (async () => {
      try {
        console.log("LOG Api Call =>> CreateUserAssignmentApi");
        const d = await CreateUserAssignmentApi({
          userIds: uIds,
          assessment_version_id: props.assessment_version_id,
          due_datetime: dueDateTime,
          availableBeforeDueDate: availableBeforeDueDate,
          attempts_allowed: 1,
          extended_attempts: 0,
          availableRightNow: rightNowFlag,
        });
        if (d.success === true) {
          setValue("timeField", "23:59");
          setUserData([]);
          setUsernames([]);
          setDays("");
          setRightNowFlag(false);
          setAvailableBeforeDueDate(0);
          setValidDueDateFlag(false);
          setBtnClickedFlag(false);
          setDueDateTime("");
          setUsersList([]);
          setUIds([]);
          handleClosePopup();
          setSelectedOption("rightNow");
          console.log(d?.response?.data, "feedback");
          setFeedbacks(d?.response?.data);
          setShowFeedback(true);
          props.handleGetAssignedUserList(!props.showPopup1);
        } else {
          setRightNowFlag(false);
          setAvailableBeforeDueDate(0);
          setValidDueDateFlag(false);
          props.handleGetAssignedUserList(!props.showPopup1);
        }
      } catch (error) {
        console.log("LOG FAIL error 165", error);
        setRightNowFlag(false);
        setAvailableBeforeDueDate(0);
        setValidDueDateFlag(false);
        handleClosePopup();
        props.handleGetAssignedUserList(!props.showPopup1);
      }
      setIsSubmitting(false);
    })();
  };
  const [calculatedDate, setCalculatedDate] = useState();

  const submit = async (data) => {
    console.log("data in popup", currentUsernameValue);
    setIsSubmitting(true);
    if (usernames.length === 0 ) {
      setError("userAdding", { message: "Username is required" });
      setIsSubmitting(false);
    }
    else if(currentUsernameValue != ""){
      setError("userAdding", { message: "Select valid Username" });
      setIsSubmitting(false);
    } else {
      setRightNowFlag(false);
      setValidDueDateFlag(false);
      setCalculatedDate();
      (async function () {
        setAvailableBeforeDueDate(0);
        if (
          (control._fields,
          control._fields.availability._f.name === "availability" &&
            control._fields.availability._f.value === "dueDate" &&
            days.trim().length === 0)
        ) {
          console.log("LOG isEmpty", days.trim().length);
          setDays("-999999");
          setIsSubmitting(false);
        }
        if (userData.length > 0) {
          console.log("LOG userData", userData);
          const dd = ""
            .concat(control._fields.dateField._f.value)
            .concat(" ")
            .concat(control._fields.timeField._f.value);
          const datee = new Date(dd).getFullYear();
          console.log("LOG setWrongDate", newValue, dd, datee);
          if (
            Number.isNaN(datee) ||
            datee == "" ||
            datee === null ||
            datee < 2023
          ) {
            console.log("LOG setWrongDate IN");
            setWrongDate(true);
            setIsSubmitting(false);
          } else {
            setWrongDate(false);
            // For New Date picker
            setDueDateTime(new Date(newValue).getTime());
            // console.log("LOG CreateUserAssignmentApi =>>", dd, control._fields);
            // console.log("LOG dueDateTime =>>", dueDateTime);
            const endOfDay = moment(newValue).endOf("day");
            setDueDateTime(endOfDay.valueOf());
            if (
              control._fields.availability._f.name === "availability" &&
              control._fields.availability._f.value === "dueDate" &&
              days.trim().length > 0
            ) {
              setAvailableBeforeDueDate(days.trim());
              var myDiffDate = new Date(
                new Date(newValue).getTime() - days * 24 * 60 * 60 * 1000
              );
              //console.log("LOG DUE DATE = ", myDate);
              var myDiffDate = new Date(
                new Date(newValue).getTime() - days * 24 * 60 * 60 * 1000
              );
              var currDate = new Date(new Date().getTime());
              //console.log("LOG DIFF DUE DATE = ", myDiffDate, currDate);
              let timeDifference = myDiffDate.getTime() - currDate;
              const diffMain = Math.ceil(timeDifference / (1000 * 3600 * 24));
              if (diffMain > 0) {
                //Valid Due Date
                // console.log("LOG CURR DATE DIFF INSIDE", diffMain, days, rightNowFlag, validDueDateFlag, wrongDate, invalidDate);
                setValidDueDateFlag(true);
                setBtnClickedFlag(false);
              } else {
                //console.log("LOG CURR DATE DIFF OUTSIDE", diffMain);
                setBtnClickedFlag(true);
                setValidDueDateFlag(false);
                setIsSubmitting(false);
              }
            }
          }
        }
        if (
          (control._fields,
          control._fields.availability._f.name === "availability" &&
            control._fields.availability._f.value === "rightNow")
        ) {
          setDays("");
          setRightNowFlag(true);
          setAvailableBeforeDueDate(0);
          setValidDueDateFlag(false);
        }
      })();
    }
  };

  useEffect(() => {
    (async () => {
      if (rightNowFlag === true) {
        if (wrongDate === false && invalidDate === false) {
          callApi();
        } else {
          setIsSubmitting(false);
        }
      } else if (validDueDateFlag === true) {
        if (!(Math.floor(days.trim()) <= 0 || days === "-1")) {
          if (wrongDate === false && invalidDate === false) {
            callApi();
          } else {
            setIsSubmitting(false);
          }
        } else {
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
      }
    })();
  }, [rightNowFlag, validDueDateFlag, wrongDate]);

  const handleRadioClick = (option) => {
    setSelectedOption(option);
    if (option === "dueDate") {
      setEnableInput(true);
    } else {
      setEnableInput(false);
    }
  };

  const handleInviteByEmail = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setValidDueDateFlag(false);
    setBtnClickedFlag(false);
    setSelectedOption("rightNow");
    setDays("");
    setUserData([]);
    setUsernames([]);
    setUsersList([]);
    setUIds([]);
    setShowPopup(false);
    setEnableInput(false);
    setNewValue(newMAXValue);
    reset({
      dateField: dueDateDefault,
      timeField: "23:59",
      availability: "rightNow",
    });
  };

  const handleUsernameDelete = (index) => {
    console.log('handleUsernameDelete',index);
    const updatedUsernames = [...usernames];
    updatedUsernames.splice(index, 1);
    setUsernames(updatedUsernames);
    const updatedUsernamesData = [...userData];
    updatedUsernamesData.splice(index, 1);
    setUserData(updatedUsernamesData);
    const updatedUsernamesDataID = [...uIds];
    updatedUsernamesDataID.splice(index, 1);
    setUIds(updatedUsernamesDataID);
  };

  const addUsername = (data) => {
    if (data) {
      setUsernames([...usernames, data]);
      setCurrentUsername("");
    }
  };

  const onItemClick = (data) => {
    console.log("LOG onItemClick handleUsernameDelete", data._source);
    setCurrentUsername(data._source.firstName + " " + data._source.lastName);
    console.log("LOG currentUsername handleUsernameDelete", currentUsername);
    addUsername(data._source.firstName + " " + data._source.lastName);
    setUserData([...userData, data]);
    setUIds([...uIds, data._source.userId]);
    clearErrors();
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
  };

  console.log("assessment_id", assessment_id);

  return (
    <>
      {feedbacks.length > 0 && (
        <FeedbackInvite
          title="Invite Status"
          feedbacks={feedbacks}
          show={showFeedback}
          onHide={() => setShowFeedback(false)}
        />
      )}
      {showInvite ? (
        <Button
          onClick={handleInviteByEmail}
          disabled={dueDate}
          className={`mt-2 email-popup ${dueDate ? "dueDateButton" : ""}`}
        >
          <img src={_getImage("SV_user.svg")} />{" "}
          {genieLabsTranslations?.inviteuser}
        </Button>
      ) : (
        ""
      )}
      <Modal
        size={"lg"}
        show={showPopup}
        onHide={() => {
          handleClosePopup();
        }}
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <ModalTitle className="modal-title w-100">
            {genieLabsTranslations?.inviteuser}
          </ModalTitle>
        </Modal.Header>
        <Form onSubmit={handleSubmit(submit)}>
          <Modal.Body className="modal-body">
            <Row>
              <Col className="mt-3" xs={12} md={12} lg={12}>
                <Controller
                  name={`userAdding`}
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name },
                    fieldState: { error },
                  }) => (
                    <div className="user-input">
                      {usernames.map((username, index) => (
                        <div className="user-tag-input" key={index}>
                          <span>{username}</span>
                          <button
                            className="user-delete-btn"
                            type="button"
                            onClick={() => {
                              console.log('handleUsernameDelete',username)
                              handleUsernameDelete(index)}}
                          >
                            ×
                          </button>
                        </div>
                      ))}
                      <AutoCompleteUsers
                        data={usersList}
                        onItemClick={(data) => onItemClick(data)}
                        userData={userData}
                        changeValue={(val)=>setCurrentUsernameValue(val)}
                      />
                    </div>
                  )}
                />
              </Col>
              <div>
                <FormErrorComponent>
                  {errors.userAdding && errors.userAdding?.message}
                </FormErrorComponent>
              </div>
            </Row>
            <Row className="mt-3">
              <Col sm={12} md={6}>
                <div className="email-heading mt-4">
                  {genieLabsTranslations?.dueDate}
                </div>
                <Row className="d-flex align-items-center">
                  <Col xs={8} sm={9} md={5}>
                    <Controller
                      name={`dateField`}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name },
                      }) => (
                        <DatePicker
                          calendarClassName="react-calendar"
                          className="mt-4"
                          name="date"
                          format={process.env.REACT_APP_DATEPICKER_FORMAT}
                          onChange={(e) => {
                            if (e != null) {
                              e.setHours(23,59,59,999);
                              setNewValue(e);}
                          }}
                          value={newValue}
                          minDate={tomorrow}
                          maxDate={datechecking1}
                          clearIcon={null}
                        />
                      )}
                    />
                    <FormErrorComponent>
                      {errors.dateField && "Enter the correct date"}
                    </FormErrorComponent>
                    {wrongDate || invalidDate ? (
                      <FormErrorComponent>
                        Date should be greater than today's date
                      </FormErrorComponent>
                    ) : null}
                  </Col>
                  <Col xs={4} sm={3} className="mt-4">
                    <img src={_getImage("date_logo.svg")} alt="Date" />
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <div className="email-heading mt-4">
                  {genieLabsTranslations?.endDate}
                </div>
                <Row className="d-flex align-items-center">
                  <Col xs={8} sm={9}>
                    <Controller
                      name={`timeField`}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name },
                      }) => (
                        <CustomInputField
                          className="timeFieldPopup"
                          type="time"
                          hookOnChange={onChange}
                          hookOnBlur={onBlur}
                          hookValue={value}
                          name={name}
                          disabled={true}
                        />
                      )}
                    />
                    <FormErrorComponent>
                      {errors.timeField && errors.timeField?.message}
                    </FormErrorComponent>
                  </Col>
                  <Col xs={4} sm={3} className="mt-3">
                    <img src={_getImage("info_icon.svg")} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-5">
              <div className="email-heading">
                {genieLabsTranslations?.availabilitySV}
              </div>
              <Col className="mt-1 d-flex align-items-center">
                <Controller
                  name={`availability`}
                  control={control}
                  render={({ field }) => (
                    <div>
                      <input
                        id="888"
                        type="radio"
                        className="mt-3"
                        onClick={() => {
                          handleRadioClick("rightNow");
                          field.onChange("rightNow");
                        }}
                        checked={selectedOption === "rightNow"}
                        onChange={(e) => {
                          setBtnClickedFlag(false);
                        }}
                      />
                      <label htmlFor="888" className="availability-desc ms-3">
                        Right Now
                      </label>
                      <Col className="d-flex align-items-center">
                        <input
                          id="777"
                          type="radio"
                          className="mt-4"
                          onClick={() => {
                            handleRadioClick("dueDate");
                            field.onChange("dueDate");
                          }}
                          checked={selectedOption === "dueDate"}
                        />
                        <Col className="ms-2 me-2">
                          <CustomInputField
                            disabled={!enableInput}
                            min={0}
                            max={999}
                            maxLength={2}
                            type="number"
                            onChange={(e) => {
                              setBtnClickedFlag(false);
                              setDays(e);
                            }}
                          />
                        </Col>
                        <label for="777" className="availability-desc mt-4">
                          {genieLabsTranslations?.dueDateSV}
                          <img
                            src={_getImage("info_icon.svg")}
                            className="m-2"
                          />{" "}
                        </label>
                      </Col>
                    </div>
                  )}
                />
              </Col>
              {
                <FormErrorComponent>
                  {errors.availability && errors.availability?.message}
                </FormErrorComponent>
              }
              {validDueDateFlag === false && btnClickedFlag === true ? (
                <FormErrorComponent>
                  Availability date must be greater than today's date
                </FormErrorComponent>
              ) : (
                ""
              )}
              {!(days === "-999999") &&
              days.trim().length > 0 &&
              Math.floor(days.trim()) <= 0 ? (
                <FormErrorComponent>
                  Days must be greater than zero
                </FormErrorComponent>
              ) : (
                ""
              )}
              {days === "-999999" ? (
                <FormErrorComponent>
                  Days before due days is required
                </FormErrorComponent>
              ) : (
                ""
              )}
            </Row>
            <Row className="mt-4 mb-4">
              <Col className="d-flex align-items-end justify-content-start justify-content-sm-end">
                <Button
                  className="cancel-button"
                  onClick={handleClosePopup}
                  type="button"
                  style={{ border: "2px solid #EE4C4C" }}
                >
                  {genieLabsTranslations?.cancelBtn}
                </Button>
              </Col>
              <Col className="d-flex align-items-end justify-content-end mt-3 mt-sm-0 justify-content-lg-start">
                <SubmitButton
                  variant="primary"
                  type="button"
                  text={genieLabsTranslations?.assignBtn}
                  onClick={handleSubmit(submit)}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};
export default InviteUserPopup;
